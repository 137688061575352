

export const menuitem=[
    {
       title:"Home",
       url:"home",
       cName:"nav-link"
    }  ,
     {
       title:"About",
       url:"about",
       cName:"nav-link"
    }  ,
     {
       title:"Roadmap",
       url:"roadmap",
       cName:"nav-link"
    }  ,
     {
       title:"Team",
       url:"team",
       cName:"nav-link"
    }  
    ,
     {
       title:"FAQ",
       url:"faq",
       cName:"nav-link"
    }  
     
];
export const td=[
   {
      title:"",
      url:"https://discord.gg/PeKwePkJe5",
      cName:"fab fa-discord"
   }  ,
   {
     title:"",
     url:"https://twitter.com/LuckyTicketsClb",
     cName:"fab fa-twitter"
  }   

]
export const tdd=[
   {
      title:"",
      url:"https://discord.gg/PeKwePkJe5",
      cName:"fab fa-discord",
      id:'so'
   }  ,
   {
     title:"",
     url:"https://twitter.com/LuckyTicketsClb",
     cName:"fab fa-twitter so",
     id:'so'
  }   
 
]
export const threecontact=[

   {
      cimg:"fas fa-phone-alt",
      title:"Give us a call",
      contact:"1-999-123-4567"
   },
   {
      cimg:"fas fa-envelope",
      title:"Send us a message",
      contact:"f180429@nu.edu.pk"
   },
   {
      cimg:"fas fa-map-marker",
      title:"Visit us",
      contact:"5210 Lahore"
   }

];
export const service=[
   {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
},
  {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
},
 {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
},
 {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
},
 {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
},
 {cimg:"fas fa-stethoscope",
   title:"General Surery",
   text:"we will take your care"
}

]
export const footer=[
   {
      title:'Department',
      lp:[
         'X-Ray',
         'Childern Care',
         'Eye Treatment',
         'Nose Treatment',
         'Surgery'
      ]
   },
   {
      title:'Our Service',
      lp:[
         'X-Ray',
         'Childern Care',
         'Eye Treatment',
         'Nose Treatment',
         'Surgery'
      ]
   },
   {
      title:'Navigation',
      lp:[
         'Home',
         'About us',
         'Eye Treatment',
         'Nose Treatment',
         'Surgery'
      ]
   }

]


import './welcome.css';
import React, { useState } from 'react';
 import L from './1.png'
 import M from './2.png'
function Welcome() {
    
  return (<>
  < br></br>
  < br></br>
<div className='wel'><img className='le' src={L}></img><h1 className='come'>WELCOME TO <br></br> <br></br> <br></br>LUCKY TICKETS <br></br><br></br> <br></br> CLUB</h1><img className='ri' src={M}></img></div>
    
< br></br>
< br></br></>
  );
}

export default Welcome;